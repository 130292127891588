import momentTimezone from "moment-timezone";
import moment from "moment";
import React from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import IntlMessage from "components/util-components/IntlMessage";
import { regionData, regionDataBeta } from "constants/SharedConstant";

dayjs.extend(utc);
dayjs.extend(timezone);

class Utils {
  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    let initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    // console.log(navTree);
    // console.log(path);
    if (navTree.path === path) {
      return navTree;
    }
    let route;
    for (let p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === "object") {
        route = this.getRouteInfo(navTree[p], path);
        if (route) {
          return route;
        }
      }
    }
    // console.log(route);
    return route;
  }

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    if (!hex) {
      return "dark";
    }
    const threshold = 130;
    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);
    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16);
    }
    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16);
    }
    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16);
    }
    function cutHex(h) {
      return h.charAt(0) === "#" ? h.substring(1, 7) : h;
    }
    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
      return "dark";
    } else {
      return "light";
    }
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
    return `#${RR}${GG}${BB}`;
  }

  /**
   * Convert RGBA to HEX
   * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
   * @return {String} HEX color
   */
  static rgbaToHex(rgba) {
    const trim = (str) => str.replace(/^\s+|\s+$/gm, "");
    const inParts = rgba.substring(rgba.indexOf("("))?.split(","),
      r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10),
      a = parseFloat(
        trim(inParts[3].substring(0, inParts[3].length - 1))
      ).toFixed(2);
    const outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
      Math.round(a * 255)
        .toString(16)
        .substring(0, 2),
    ];

    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = "0" + part;
      }
    });
    return `#${outParts.join("")}`;
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive;
    }
    if (number < 0) {
      return negative;
    }
    return null;
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === "number" && typeof b[key] === "number") {
      return a[key] - b[key];
    }

    if (typeof a[key] === "string" && typeof b[key] === "string") {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
    return;
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] === value);
    }
    return data;
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] !== value);
    }
    return data;
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (let key in item) {
        if (item[key] == null) {
          continue;
        }
        if (
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true;
        }
      }
    };
    list = list.filter((value) => searchText(value));
    return list;
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    let breakpoints = [];
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    }
    return breakpoints;
  }

  static getTimezone = () => {
    const result = [];

    momentTimezone.tz.names().map((item) =>
      result.push({
        desc: `(GMT ${moment.tz(item).format("Z")}) ${item}`,
        country: item,
        offset: moment.tz(item).format("Z"),
      })
    );

    // offsetTmz = offsetTmz.sort((a, b) => a.offset.toString().localeCompare(b.offset.toString()));

    return result;
  };

  static decodeId(id) {
    if (!id || id === "") return "";

    try {
      const decoded = window.atob(id);
      return decoded.split(":")[1];
    } catch (error) {
      // If decoding fails, return the original ID
      return id;
    }
  }

  static removeKey(obj, keyToRemove = "__typename") {
    if (obj === null || typeof obj !== "object") return obj;

    if (Array.isArray(obj)) {
      return obj.map((item) => Utils.removeKey(item, keyToRemove));
    }

    const newObj = {};
    for (const key in obj) {
      if (key !== keyToRemove) {
        newObj[key] = Utils.removeKey(obj[key], keyToRemove);
      }
    }
    return newObj;
  }

  static regexPassword =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).[a-zA-Z0-9#?!@$%^&*-]{7,}$/;

  static tinyInit = {
    height: 300,
    branding: false,
    menubar: false,
    toolbar_mode: "sliding",
    icons: "thin",
    plugins: [
      "advlist",
      "autolink",
      "lists",
      "link",
      "image",
      "charmap",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "fullscreen",
      "insertdatetime",
      "media",
      "table",
      "code",
      "help",
      "wordcount",
    ],
    toolbar:
      "undo redo | fontsizeselect | fontselect | formatselect |  bold italic underline strikethrough | forecolor backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table link code preview | image ",
    font_formats:
      "Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;AkrutiKndPadmini=Akpdmi-n",
    fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
    file_picker_types: "image",
    image_description: false,
    paste_data_images: true,
  };

  static uploadImage = async (blobInfo) => {
    const token = localStorage.getItem("auth_token");
    const formData = new FormData();
    formData.append("file", blobInfo.blob());
    formData.append("projectId", this.getProjectId());

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/uploadcs/`,
            {
                method: "POST",
                body: formData,
                headers: {
                    authorization: token ? `Bearer ${token}` : "",
                    projectId: this.getProjectId(),
                    type: "cs",
                },
            }
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const result = await response.json();

        if (result.status) {
            const imageUrl = `${result.cdn}${result.path}`;
            return imageUrl; // 성공 시 이미지 URL 반환
        } else {    
          throw new Error(<IntlMessage id="file upload failed" />); // 실패 시 에러 발생
        }
    } catch (error) {
        console.error(<IntlMessage id="file upload failed" />, error);
        throw new Error(<IntlMessage id="file upload failed" />); // 실패 시 에러 발생
    }
  };

  static handleModalTitle = ({ modalType }) => {
    switch (modalType) {
      case "add":
        return <IntlMessage id="Add" />;
      case "edit":
        return <IntlMessage id="Edit" />;
      case "view":
        return <IntlMessage id="View" />;
      default:
        return <IntlMessage id="View" />;
    }
  };

  static handleModalOkText = ({ modalType }) => {
    switch (modalType) {
      case "add":
        return <IntlMessage id="Add" />;
      case "edit":
        return <IntlMessage id="Save" />;
      case "view":
        return <IntlMessage id="OK" />;
      default:
        return <IntlMessage id="OK" />;
    }
  };

  static getRegionData(regionNo) {
    let objData;

    if (process.env.REACT_APP_BETA === "true") {
      objData = regionDataBeta[String(regionNo)];
    } else {
      objData = regionData[String(regionNo)];
    }

    if (!objData) {
      return regionData["1"];
    }

    return objData;
  }

  // used in policies and terms
  static getWebAccessAddress = ({ project, id, projectId }) => {
    const bucket = project?.ncp?.objectstorage_bucket;
    const ncpId = project?.ncp_project_id;
    let cdn = project?.cdn;

    const baseUrl = process.env.REACT_APP_APPS_URL || 'https://gamepot.apigw.ntruss.com/gpapps/v1';
    return `${baseUrl}/pages/${id}?projectId=` + projectId; 
  };

  static createMenuItems = (submenu, currentPath, renderComponent) => {
    return submenu.map((item) => {
      const pathKey = item.path?.split("/").pop();
      return {
        label: React.createElement(IntlMessage, { id: item.title }),
        key: pathKey,
        children: currentPath === pathKey ? renderComponent(pathKey) : null,
      };
    });
  };

  static findSubmenu = (data, targetKey, currentPath, renderComponent) => {
    for (let item of data) {
      if (item.key === targetKey && item.submenu) {
        return this.createMenuItems(item.submenu, currentPath, renderComponent);
      }
      if (item.submenu) {
        const result = this.findSubmenu(
          item.submenu,
          targetKey,
          currentPath,
          renderComponent
        );
        if (result) return result;
      }
    }
    return null;
  };

  static convertObjectTo2DArray = (obj, index) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (key.startsWith("day_")) {
        const dayNumber = parseInt(key.split("_")[1], 10);
        acc.push([dayNumber === 30 ? 15 : dayNumber, index, obj[key]]);
      }
      return acc;
    }, []);
  };

  static getColor = ({ elm, record }) => {
    const max = 100;
    const ratio = parseFloat(((elm / record[1]) * 100).toFixed(2)) / max;

    // Define RGB values for yellow and red
    const yellow = { r: 255, g: 255, b: 0 };
    const red = { r: 255, g: 0, b: 0 };

    // Interpolate between yellow and red
    const r = Math.round(red.r * ratio + yellow.r * (1 - ratio));
    const g = Math.round(red.g * ratio + yellow.g * (1 - ratio));
    const b = Math.round(red.b * ratio + yellow.b * (1 - ratio));

    return `rgb(${r}, ${g}, ${b})`;
  };

  // this is used for a timezone date picker
  static cellRender = (current, info, timezone = null) => {
    if (typeof current === "object") {
      current = timezone ? current.tz(timezone) : current.tz();
    }
    if (info.type !== "date") {
      return info.originNode;
    }
    if (typeof current === "number") {
      return <div className="ant-picker-cell-inner">{current}</div>;
    }
    return (
      <div
        className="ant-picker-cell-inner"
        title={current.format("YYYY/MM/DD")}
      >
        {typeof current === "object" ? current.date() : current}
      </div>
    );
  };

  static disablePastDate = (current) =>
    current && current < dayjs().tz().startOf("day");

  static disableFutureDate = (current) => current > dayjs().tz().startOf("day");

  static getProjectId() {
    const url = window.location.href; // Get the current URL
    const segments = url.split("/"); // Split the URL by '/'
    const uuid = segments[4]; // The UUID is in the 4th index
    return uuid;
  }

  static getCompanyId() {
    const url = window.location.href; // Get the current URL
    const segments = url.split("/"); // Split the URL by '/'
    const uuid = segments[3]; // The UUID is in the 4th index
    return uuid;
  }

  static updateMenuPaths = (menuItems) => {
    const updatedItems = menuItems.map((item) => {
      // Replace placeholders in the path
      let updatedPath = item.path
        .replace(":companyId", Utils.getCompanyId())
        .replace(":projectId", Utils.getProjectId());

      // Check if there are submenu items and apply the same logic recursively
      if (item.submenu && item.submenu.length > 0) {
        return {
          ...item,
          path: updatedPath,
          submenu: Utils.updateMenuPaths(item.submenu), // Recursive call for submenus
        };
      }

      // Return the updated item
      return {
        ...item,
        path: updatedPath,
      };
    });

    return updatedItems;
  };

  static generateTimeArray() {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      // Loop over hours from 00 to 23
      for (let minute = 0; minute < 60; minute++) {
        // Loop over minutes from 00 to 59
        // Format the time and push to times array
        times.push(
          `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`
        );
      }
    }
    return times;
  }

  static stripHtmlTags(str) {
    if (typeof str !== 'string') {
      return "";
    }
    return str.replace(/<[^>]*>/g, "");
  }

  static applyLocaleToLink({ locale, link }) {
    return locale !== "zh-Hans"
      ? link.replace(/\/docs\//, `/docs/${locale}/`)
      : link.replace(/\/docs\//, `/docs/en/`);
  }

  static jsonStringToArrayOfObjects(jsonString) {
    const parsedObject = JSON.parse(jsonString);
    return Object.keys(parsedObject).map((key) => {
      return { [key]: parsedObject[key] };
    });
  }

  static getErrorMessages(error) {
    return error?.response?.data?.error;
  }

  static getChatErrorMessages(error) {
    return error?.message;
  }

  static getEditorLanguage(locale) {
    switch (locale) {
      case "zh-Hans":
        return "zh_CN";
      case "ko":
        return "ko_KR";
      case "en":
        return "en_US";
      case "th":
        return "th_TH";
      case "ja":
        return "ja_JP";
      default:
        return locale;
    }
  }

  static isTokenExpired = (token) => {
    try {
      const payload = token.split(".")[1]; // Get the payload
      const decodedPayload = JSON.parse(window.atob(payload)); // Decode Base64Url and parse JSON
      const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
      return decodedPayload.exp < currentTime;
    } catch (error) {
      console.error("Failed to decode token", error);
      return false; // Assume the token is invalid if it fails to decode
    }
  };

  static getLocale() {
    // 브라우저의 언어 설정을 사용
    if (typeof navigator !== 'undefined' && navigator.language) {
      return navigator.language;
    }
    
    // Node.js 환경에서는 process.env.LANG을 사용할 수 있습니다
    if (typeof process !== 'undefined' && process.env.LANG) {
      return process.env.LANG.split('.')[0];
    }
    
    // 기본값으로 'en-US' 반환
    return 'en-US';
  }
  static getNumberFormatWithCurrency(currency, number) {
    let result = new Intl.NumberFormat(this.getLocale(), { style: 'currency', currency }).format(number);
    if (isNaN(number)) result = '0';

    return result;
  }

  static isNaver = process.env.REACT_APP_SOURCE === "NAVER";
}

export default Utils;
